import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// noinspection JSUnusedGlobalSymbols
import { createSvgIcon } from '@mui/material/utils';
export const MeetingRoom = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("path", { fill: 'currentColor', d: 'M12.117 11.916q1.008 0 1.711-.72a2.4 2.4 0 0 0 .703-1.75q0-1.007-.703-1.727A2.3 2.3 0 0 0 12.118 7q-.986 0-1.688.72a2.38 2.38 0 0 0-.703 1.726q0 1.032.703 1.75.702.72 1.687.72m-7.805.576q-1.992 0-3.164 1.199Q0 14.889 0 16.137q0 .407.234.647.235.216.797.216h4.453q-.468-.696-.023-1.847a4.5 4.5 0 0 1 1.43-1.894 4.7 4.7 0 0 0-1.125-.552 4.5 4.5 0 0 0-1.453-.215m7.688 0q-2.297 0-3.633 1.27-1.312 1.271-1.312 2.423 0 .408.28.623.282.192.962.192h7.383q.703 0 .984-.192.282-.216.281-.623 0-1.152-1.336-2.422Q14.274 12.49 12 12.492m6.516 4.484q.446-.672-.024-1.822a4.4 4.4 0 0 0-1.383-1.895 4.7 4.7 0 0 1 1.125-.552q.633-.24 1.453-.24 1.993 0 3.141 1.224Q24 14.889 24 16.137q0 .407-.234.623t-.797.216zM6.398 9.854q0 .863-.585 1.463-.587.6-1.407.6-.843 0-1.43-.6-.585-.6-.585-1.463 0-.84.586-1.44.585-.599 1.43-.599.82 0 1.406.6.585.6.585 1.439m13.454 2.062q.82 0 1.406-.6.585-.6.586-1.462 0-.84-.586-1.44-.586-.599-1.406-.599-.845 0-1.43.6t-.586 1.439q0 .863.586 1.463.585.6 1.43.6' }) }), 'MeetingRoom');
export const Space = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("path", { fill: 'currentColor', fillRule: 'evenodd', d: 'M12 4.142 6.04 7.508 12 10.875l5.96-3.367zM5 9.226l6 3.389v6.733L5 15.96zm6.36 12.63c.208.118.43.16.64.14.21.02.432-.022.64-.14l7.8-4.405c.346-.196.56-.564.56-.963V7.68c0-.252-.081-.477-.214-.657a1.1 1.1 0 0 0-.45-.477l-7.797-4.404a1.1 1.1 0 0 0-1.078 0L3.665 6.546a1.1 1.1 0 0 0-.45.477C3.08 7.203 3 7.428 3 7.68v8.808c0 .399.214.767.56.963zm3.652-3.644L13 19.348v-6.733l6-3.39v6.734l-2 1.13v-3.053c0-.555-.448-1.004-1-1.004s-1 .45-1 1.004v4.017q0 .081.012.159M6.5 14.546a1 1 0 0 0 .508.87l1.746.987a.5.5 0 0 0 .746-.436v-1.862a1 1 0 0 0-.508-.87l-1.746-.987a.5.5 0 0 0-.746.435z', clipRule: 'evenodd' }) }), 'Space');
export const Parking = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("path", { fill: 'currentColor', d: 'M12.292 3H7.491q-.82 0-1.405.586-.585.585-.586 1.43v13.992q0 .82.586 1.406Q6.67 21 7.49 21q.843 0 1.429-.586.585-.586.585-1.406V15h2.998q1.335 0 2.483-.54a5.9 5.9 0 0 0 1.99-1.476 6.4 6.4 0 0 0 1.242-2.156 6.05 6.05 0 0 0 .234-2.601 5.8 5.8 0 0 0-.703-2.086A5.9 5.9 0 0 0 16.345 4.5a6.2 6.2 0 0 0-1.874-1.102A6 6 0 0 0 12.293 3m.399 8.016H9.505V7.008h3.185q.82 0 1.406.586.609.586.609 1.406 0 .845-.61 1.43-.585.585-1.405.586' }) }), 'Parking');
export const Workspace = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("path", { fill: 'currentColor', d: 'M19.986 2.5H4.014q-.843 0-1.429.586Q2 3.672 2 4.515v10.988q0 .82.585 1.405t1.43.586h5.994v2.015H9.002a1 1 0 0 0-.726.304.93.93 0 0 0-.28.68q0 .42.28.726.305.28.726.281h5.996a.95.95 0 0 0 .702-.281 1 1 0 0 0 .305-.726.9.9 0 0 0-.305-.68.92.92 0 0 0-.702-.304H13.99v-2.015h5.995q.843 0 1.428-.586.585-.585.586-1.406V4.515q0-.843-.585-1.43a1.94 1.94 0 0 0-1.43-.585m0 13.003H4.014V4.515h15.972z' }) }), 'Workspace');
export const ReceptionSpace = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("path", { fill: 'currentColor', d: 'M9.869 4.86q-.422.423-.656.985-.21.562-.21 1.15h5.995q0-.588-.234-1.15a2.7 2.7 0 0 0-.633-.985 2.7 2.7 0 0 0-.983-.633A3 3 0 0 0 12 3.993q-.585 0-1.148.234a2.7 2.7 0 0 0-.983.633M7.012 8.987H4.014v11.02h15.972v-1.993H4.014v-2.016h15.972v-7.01h-2.998v2.016q0 .187-.07.375a.91.91 0 0 1-.539.539 1.1 1.1 0 0 1-.374.07q-.21 0-.398-.07a.91.91 0 0 1-.54-.54 1.1 1.1 0 0 1-.07-.374V8.987H9.003v2.017q0 .187-.07.375a.91.91 0 0 1-.539.539q-.186.07-.398.07a1.1 1.1 0 0 1-.374-.07.91.91 0 0 1-.539-.54 1.1 1.1 0 0 1-.07-.374zm0-1.993q0-.985.374-1.899a4.887 4.887 0 0 1 2.693-2.72A5.1 5.1 0 0 1 12 2q.984 0 1.897.375.936.375 1.64 1.079a4.8 4.8 0 0 1 1.077 1.641q.374.915.374 1.9h2.998q.843 0 1.428.585.585.587.586 1.407v11.02a1.92 1.92 0 0 1-.585 1.407q-.585.586-1.43.586H4.016a1.94 1.94 0 0 1-1.43-.586A1.92 1.92 0 0 1 2 20.007V8.987q0-.82.585-1.407.585-.585 1.43-.586z' }) }), 'ReceptionSpace');
export const OtherArea = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("path", { fill: 'currentColor', fillRule: 'evenodd', d: 'M21 4.5A1.5 1.5 0 0 0 19.5 3h-5A1.5 1.5 0 0 0 13 4.5V8H9.5A1.5 1.5 0 0 0 8 9.5V13H4.5A1.5 1.5 0 0 0 3 14.5v5A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5zM19 5v14H5v-4h3.5a1.5 1.5 0 0 0 1.5-1.5V10h3.5A1.5 1.5 0 0 0 15 8.5V5z', clipRule: 'evenodd' }) }), 'OtherArea');
export const WorkBooth = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsxs("g", { fill: 'currentColor', children: [_jsx("path", { fillRule: 'evenodd', d: 'M3 20V4a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v16zm16 0V4H5v16z', clipRule: 'evenodd' }), _jsx("path", { fillRule: 'evenodd', d: 'M5 11h5.5a1 1 0 1 1 0 2H5z', clipRule: 'evenodd' }), _jsx("path", { d: 'M17 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0' }), _jsx("path", { fillRule: 'evenodd', d: 'M15.5 11.25c.69 0 1.25.56 1.25 1.25V16A1.75 1.75 0 0 1 15 17.75h-2.25V20h-2.5v-3c0-.966.784-1.75 1.75-1.75h2.25V12.5c0-.69.56-1.25 1.25-1.25', clipRule: 'evenodd' }), _jsx("path", { d: 'M1 21a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1' })] }) }), 'WorkBooth');
export const OpenSpace = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("path", { fill: 'currentColor', d: 'M3 12.347c-1.1 0-2-.885-2-1.966V5.466C1 4.385 1.9 3.5 3 3.5h8c1.1 0 2 .885 2 1.966v4.915c0 1.081-.9 1.966-2 1.966H9v1.763c0 .437-.539.657-.854.347L6 12.347zm18 5.898c1.1 0 2-.885 2-1.966v-4.915c0-1.081-.9-1.966-2-1.966h-6v.983c0 2.163-1.8 3.932-4 3.932v1.966c0 1.081.9 1.966 2 1.966h2v1.762c0 .438.539.658.854.348L18 18.245z' }) }), 'OpenSpace');
export const TenantArea = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("g", { fill: 'currentColor', children: _jsx("path", { d: 'M13 5a4 4 0 1 1-8 0 4 4 0 0 1 8 0M10.841 11.16A10.6 10.6 0 0 0 9 11c-4.418 0-8 2.686-8 6 0 .59.53 1 1.119 1H9.5v-4.918a2 2 0 0 1 1.025-1.746zM16.507 9.93l-5 2.833a1 1 0 0 0-.507.87v5.534a1 1 0 0 0 .507.87l5 2.834a1 1 0 0 0 .986 0L19 22.017V18a1 1 0 1 1 2 0v2.883l1.493-.846a1 1 0 0 0 .507-.87v-5.534a1 1 0 0 0-.507-.87l-5-2.834a1 1 0 0 0-.986 0' }) }) }), 'TenantArea');
export const WorkSpot = createSvgIcon(_jsx("svg", { xmlns: 'http://www.w3.org/2000/svg', width: 24, height: 24, fill: 'none', children: _jsx("path", { fill: 'currentColor', d: 'M3.007 5.5h17.986q.421 0 .703.305a.92.92 0 0 1 .304.702 1 1 0 0 1-.305.726.95.95 0 0 1-.702.281v9.979a1 1 0 0 1-.304.726.95.95 0 0 1-.703.281q-.398 0-.703-.281a1.03 1.03 0 0 1-.28-.726v-.984h-4.005v.984a1 1 0 0 1-.305.726.95.95 0 0 1-.702.281q-.399 0-.703-.281a1.03 1.03 0 0 1-.281-.726V7.514h-8.01v9.979a1 1 0 0 1-.304.726.93.93 0 0 1-.679.281q-.421 0-.726-.281a1.03 1.03 0 0 1-.281-.726V7.514q-.422 0-.726-.28A1.03 1.03 0 0 1 2 6.506q0-.42.281-.702a1 1 0 0 1 .726-.305m12.998 4.497v.516h1.99v-.516a.477.477 0 0 0-.492-.492h-1.006a.477.477 0 0 0-.492.492m0 4.006v.492h1.99v-.492a.477.477 0 0 0-.492-.492h-1.006a.477.477 0 0 0-.492.492' }) }), 'WorkSpot');
