import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
import * as Icon from '@workhub/icons';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { Button as ButtonBase } from 'react-aria-components';
import { Ripple } from '../ripple';
import styles from './TextButton.module.css';
const _TextButton = ({ type = 'primary', processing = false, done = false, disabled, className, onClick, children }, ref) => {
    return (_jsxs(ButtonBase, { ref: ref, className: clsx(styles.root, className), isDisabled: disabled, "data-type": type, "data-processing": processing || null, "data-done": done || null, onPress: onClick, children: [_jsx("span", { className: styles.label, children: children }), processing && (_jsx("span", { className: styles.processing, children: _jsx(CircularProgress, { size: 24 }) })), done && (_jsx("span", { className: styles.done, children: _jsx(Icon.done, {}) })), _jsx(Ripple, {})] }));
};
export const TextButton = forwardRef(_TextButton);
