import axios, {AxiosError, AxiosResponse} from 'axios';

import {FirebaseFunctions} from '../firebase/functions/firebase-functions';
import Logger from '../logger/logger';

const logger = Logger.create('accountApi');

/**
 * workhubAccountのAPIにアクセスするためのaxiosインスタンスを取得する
 * IDトークンは利用するところで取得して渡す
 */
const getBaseUrl = () => {
  if (import.meta.env.VITE_FIREBASE_PROJECT_ID === 'workhub-local') {
    // coreのrunのほうにauth/authorityだけ生やした
    // 他のパスは利用を検知するためにあえてコケるようにしてある
    return import.meta.env.VITE_CORE_DEFAULT_API_URL;
  } else if (import.meta.env.VITE_LOCAL_API === 'account') {
    return `${import.meta.env.VITE_API_HOST_LOCAL}/workhubAccount/v1`;
  } else {
    return `${import.meta.env.VITE_API_HOST_LOAD_BALANCER}/workhubAccount/v1`;
  }
};

const getAccountAxios = () => {
  return axios.create({
    baseURL: getBaseUrl(),
    headers: {
      'content-type': 'application/json',
    },
  });
};

export const accountApi = getAccountAxios();
accountApi.interceptors.response.use(
  (res: AxiosResponse) => {
    logger.trace(res.request?.responseURL, res);
    return res;
  },
  (e: AxiosError) => {
    const errRes = (e as AxiosError<{code: number}>)?.response;
    if (errRes?.data && errRes.data.code === 401 && FirebaseFunctions.logoutByExpired) {
      logger.info(`failed to authenticate. logouting...`, {res: errRes}, e);
      setTimeout(() => {
        // 同時にalgolia待ち等あるので効果は薄いけど一応次のキューに詰んで最低限state更新後に実行されるようにする
        FirebaseFunctions.logoutByExpired?.();
      });
    } else {
      logger.error(`failed to ${e.response?.config.method} ${e.response?.config.url}`, e);
    }
    throw e;
  }
);
