import {AuthorityLevel} from '@bitkey-service/workhub-types/lib/firestore/organizations/authority-pattern/firestoreTypesOrganizationsAuthorityPattern';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import {WHColor} from '@/common/styles/whColor';

import {Feature} from '../../common/feature-control/featureDefinitions';
import useDict from '../../common/hooks/useDict';
import {useLoginUser} from '../../common/hooks/useLoginUser';
import {Locale} from '../../common/redux/state-types/localeStateType';
import WIconNoticeError from '../../components/figma/icon/notice/WIconNoticeError';

const FailedAuthorize = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
`;

const dictDef = {
  description1: {
    default: {
      default: '操作できるメニューがありません。システム管理者に確認してください。',
      [Locale.en_US]: 'There is no menu available for operation. Check with your system administrator.',
    },
  },
  noAuthorityDescription: {
    default: {
      default: 'ページを利用する権限がありません。利用する権限が必要な場合は、システム管理者に確認してください。',
      [Locale.en_US]:
        'No permission to open this page. Check with your system administrator if you need the permission.',
    },
  },
};

const useStyles = makeStyles()(theme => ({
  messageFailed: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: WHColor.text.semanticError,
  },
}));

interface P {
  noAuthority?: boolean;
}

const ErrorDisplayScreen: React.FC<P> = props => {
  const dict = useDict(dictDef);
  const {classes} = useStyles();
  const user = useLoginUser();
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    setIsFailed(false);
    const ignoreFeatures: Feature[] = [
      Feature.V2BitlockApp,
      Feature.V2ReceptionApp,
      Feature.V2WorkhubAppOrganization,
      Feature.WorkhubAppPersonalizedNfcCard,
      Feature.AzbilCloudOperation,
      Feature.AboutBitkey,
    ];
    const levels = Object.keys(user.authority).map(key =>
      ignoreFeatures.includes(key as Feature) ? AuthorityLevel.None : user.authority[key as Feature]
    );

    if (user.superUser || (levels.length > 0 && !levels.every(l => l === AuthorityLevel.None))) {
      return;
    }

    setIsFailed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isFailed ? (
        <FailedAuthorize>
          <WIconNoticeError size={112} />
          <Typography className={classes.messageFailed}>{dict.description1}</Typography>
        </FailedAuthorize>
      ) : props.noAuthority ? (
        <FailedAuthorize>
          <WIconNoticeError size={112} />
          <Typography className={classes.messageFailed}>{dict.noAuthorityDescription}</Typography>
        </FailedAuthorize>
      ) : (
        <div />
      )}
    </>
  );
};
export default React.memo(ErrorDisplayScreen);
