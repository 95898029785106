import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip as TooltipBase, TooltipTrigger as TooltipTriggerBase } from 'react-aria-components';
import styles from './Tooltip.module.css';
const TooltipRoot = ({ placement, children }) => {
    return (_jsx(TooltipBase, { className: styles.root, placement: placement, children: children }));
};
const TooltipTrigger = ({ open, children }) => {
    return (_jsx(TooltipTriggerBase, { delay: 0, closeDelay: 0, isOpen: open, children: children }));
};
export const Tooltip = Object.assign(TooltipRoot, {
    Trigger: TooltipTrigger,
});
