import {type FC, memo, useMemo} from 'react';
import {isMobileOnly} from 'react-device-detect';
import {useSelector} from 'react-redux';
import {createBrowserRouter, Navigate, type RouteObject, RouterProvider, useRouteError} from 'react-router-dom';

import {FeatureControl} from '@/common/feature-control/featureControl';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {useOrganization} from '@/common/hooks/useOrganization';
import {UserState} from '@/common/redux/state-types/userStateType';
import {RootState} from '@/common/redux/store/rootStateType';
import ErrorDisplayScreen from '@/wscreens/no-authority/ErrorDisplayScreen';
import {paths} from '@/wscreens/routing/routing';

import {Routing} from './Routing';

const mobileOnlyRoutes: RouteObject[] = [
  {
    path: '/reservation',
    lazy: () => import('@/mobile/screens/reservation/ReservationFromQr'),
  },
  {
    path: '/form/satisfaction/complete',
    lazy: () => import('@/mobile/screens/form/satisfaction/FormSatisfactionMobileCompleteScreen'),
  },
  {
    path: '/form/satisfaction/:satisfactionId',
    lazy: () => import('@/mobile/screens/form/satisfaction/FormSatisfactionMobileScreen'),
  },
  {
    path: '*',
    lazy: () => import('@/mobile/screens/no-page/NoPage'),
  },
];

/**
 * React Router の内側でエラーが起きた時に再スローすることで React Router がデフォルトで用意しているエラー画面を表示しないようにする
 * またエラーを外側にスローすることで何かしら対処が行われることを期待する.
 * 例えばモジュールロード関連のエラーは Vite が捕捉して vite:preloadError イベントを発行する仕組みがあるため, そちらを利用する.
 *
 * FIXME: 一方でユーザにとっては真っ白な画面が表示されることがあるので, 復帰不能なエラーが発生したときのエラー画面を用意しておいたほうが良い
 */
const ErrorBoundary: FC = (): never => {
  throw useRouteError();
};

const Router: FC = () => {
  const {activations, authority} = useSelector<RootState, UserState>(s => s.user);
  const {tempAccessControlVersionSettings} = useOrganization();
  const user = useLoginUser();
  const authorizedRoutes = useMemo(() => {
    const activationGroups = activations ? activations.map(a => a.activationGroup) : [];
    return FeatureControl.createInstance(user.superUser)
      .getFilteredRouting({activationGroups, featureAuthority: authority, tempAccessControlVersionSettings})
      .flatMap(r => {
        if (r.type === 'withChildren') {
          return r.children
            .filter(() => {
              // TODO 権限
              return true;
            })
            .map(c => ({
              path: c.resource.type === 'route' ? c.resource.path : c.resource.url,
              component: c.resource.type === 'route' ? c.resource.component : undefined,
            }));
        } else {
          return {
            path: r.resource.type === 'route' ? r.resource.path : r.resource.url,
            component: r.resource.type === 'route' ? r.resource.component : undefined,
          };
        }
      });
  }, [activations, authority, tempAccessControlVersionSettings, user.superUser]);

  const notAuthorizedPaths = useMemo(() => {
    const authorizedRoutePath = authorizedRoutes.map(r => r.path);
    return paths.filter(path => !authorizedRoutePath.includes(path));
  }, [authorizedRoutes]);

  const routes = useMemo((): RouteObject[] => {
    if (isMobileOnly) {
      return mobileOnlyRoutes;
    }

    return [
      ...authorizedRoutes.filter(r => !!r.path).map(({path, component: Component}) => ({path, Component})),
      // 権限持ってないだけで画面が存在する場合は、エラー画面表示
      ...notAuthorizedPaths.map(path => ({path, element: <ErrorDisplayScreen noAuthority />})),
      // 存在していない画面を開いたらrootにリダイレクト
      {
        path: '*',
        element: <Navigate to='/' />,
      },
    ];
  }, [authorizedRoutes, notAuthorizedPaths]);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          element: <Routing />,
          errorElement: <ErrorBoundary />,
          children: routes,
        },
      ]),
    [routes]
  );

  return <RouterProvider router={router} />;
};

export default memo(Router);
