import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef } from 'react';
import { Button as ButtonBase } from 'react-aria-components';
import { Ripple } from '../ripple';
import styles from './IconButton.module.css';
const _IconButton = ({ background = false, disruptive = false, disabled, className, onClick, children }, ref) => {
    return (_jsxs(ButtonBase, { ref: ref, className: clsx(styles.root, className), isDisabled: disabled, "data-background": background || null, "data-disruptive": disruptive || null, onPress: onClick, children: [children, _jsx(Ripple, {})] }));
};
export const IconButton = forwardRef(_IconButton);
