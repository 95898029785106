import {ApiTypesOrganizationNameGetRes} from '@bitkey-service/v2_core-types/lib/api/organization/apiTypesOrganizations';
import {StoreTypesOrgAccessControlTargetPattern} from '@bitkey-service/v2_core-types/lib/store/organizations/access-control-target-patterns/storeTypesOrgAccessControlTargetPattern';
import {StoreTypesOrgChargeDetail} from '@bitkey-service/v2_core-types/lib/store/organizations/charge-detail/storeTypesOrgChargeDetail';
import {StoreTypesOrgClaimCycle} from '@bitkey-service/v2_core-types/lib/store/organizations/claim-cycle/storeTypesOrgClaimCycle';
import {
  ContractStatus,
  StoreTypesOrgContract,
} from '@bitkey-service/v2_core-types/lib/store/organizations/contract/storeTypesOrgContract';
import {StoreTypesOrgContractMembers} from '@bitkey-service/v2_core-types/lib/store/organizations/contract/storeTypesOrgContractMembers';
import {StoreTypesOrgContractVersions} from '@bitkey-service/v2_core-types/lib/store/organizations/contract/versions/storeTypesOrgContractVersions';
import {StoreTypesOrgContractPlans} from '@bitkey-service/v2_core-types/lib/store/organizations/contract-plans/storeTypesOrgContractPlans';
import {
  InChargeOfContract,
  StoreTypesOrgCustomer,
} from '@bitkey-service/v2_core-types/lib/store/organizations/customer/storeTypesOrgCustomer';
import {StoreTypesOrgPayment} from '@bitkey-service/v2_core-types/lib/store/organizations/payment/storeTypesOrgPayment';
import {V2StoreTypesOrgPeople} from '@bitkey-service/v2_core-types/lib/store/organizations/people/v2_storeTypesOrgPeople';
import {StoreTypesOrgReservations} from '@bitkey-service/v2_core-types/lib/store/organizations/reservations/storeTypesOrgReservations';
import {V2StoreTypesOrgThing} from '@bitkey-service/v2_core-types/lib/store/organizations/things/v2_storeTypesOrgThing';
import {StoreTypesOrgThirdPlaceAreaSettings} from '@bitkey-service/v2_core-types/lib/store/organizations/third-place-area-setting/storeTypesOrgThirdPlaceAreaSettings';
import {createSlice} from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import {OrgNfcCard} from '@/common/firebase/firestore/references/firestoreOrgNfcCards';
import {
  ContractDetailControlDbState,
  ContractDetailDialogFlag,
  ContractDetailLoadedDbState,
  ContractDetailLoadedFlag,
  ContractDetailState,
} from '@/common/redux/screens/state/contractDetailState';
import {
  BillingResponse,
  ResGetBillingDetail,
} from '@/v2_api/workhub-core/organizations/billings/v2_ApiWorkhubCoreCoworkingBillings';
import {BceCreditCardType} from '@/v2_api/workhub-core/organizations/credit-cards/v2_ApiWorkhubCoreCreditCards';
import {ResponseGetRefund} from '@/v2_service/refund/refundService';
import {MoveInArea} from '@/wscreens/contracts/cancel/types/contractCancelTypes';
import {ContractKeyItem} from '@/wscreens/contracts/tabs/item/key/TPKeyItems';
import {ContractDetailHeaderTabId} from '@/wscreens/contracts/v2Detail/service/header/useContractDetailHeaderTabs';
import {BceContract} from '@/wscreens/contracts/v2Detail/types/v2ContractDetailTypes';

const InitialLoadedDBState: ContractDetailLoadedDbState = {};

const InitialControlState: ContractDetailControlDbState = {
  tabId: 'overview',
  selectedContractVersion: 1,
  dialogOpen: {},
};
const initialState: ContractDetailState = {
  db: InitialLoadedDBState,
  control: InitialControlState,
  added: {},
};

const contractDetailSlice = createSlice({
  name: 'contractDetail',
  initialState,
  reducers: {
    addAccountBankName: (
      state,
      action: {
        payload: string;
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          accountBankName: action.payload,
        },
      };
    },
    addContractPlanSpaces: (
      state,
      action: {
        payload: string;
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          accountBankName: action.payload,
        },
      };
    },
    addRefundBankName: (
      state,
      action: {
        payload: string;
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          refundBankName: action.payload,
        },
      };
    },
    addContract: (
      state,
      action: {
        payload: {
          contract?: StoreTypesOrgContract;
          currentActiveContract?: StoreTypesOrgContract;
          contractVersions?: StoreTypesOrgContractVersions[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addBceContract: (
      state,
      action: {
        payload: {
          bceContract?: BceContract;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addContractPlan: (
      state,
      action: {
        payload: {
          contractPlan?: StoreTypesOrgContractPlans;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addCancelContractReservations: (
      state,
      action: {
        payload: {
          cancelContractReservations: StoreTypesOrgReservations[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    updateCurrentContract: (
      state,
      action: {
        payload: {
          contract?: StoreTypesOrgContract;
          currentActiveContract?: StoreTypesOrgContract;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    updateCurrentContractStatus: (
      state,
      action: {
        payload: ContractStatus;
      }
    ) => {
      if (!state.db.contract || !state.db.currentActiveContract) {
        return cloneDeep(initialState);
      }
      return {
        ...state,
        db: {
          ...state.db,
          contract: {
            ...state.db.contract,
            status: action.payload,
          },
          currentActiveContract: {
            ...state.db.currentActiveContract,
            status: action.payload,
          },
        },
      };
    },
    addCustomer: (
      state,
      action: {
        payload: {
          customer?: StoreTypesOrgCustomer;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addSpaces: (
      state,
      action: {
        payload: {
          spaces?: MoveInArea[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addClaimCycles: (
      state,
      action: {
        payload: {
          claimCycles?: StoreTypesOrgClaimCycle[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addCustomerMembers: (
      state,
      action: {
        payload: {
          customerMembers?: V2StoreTypesOrgPeople[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addTargetPatterns: (
      state,
      action: {
        payload: {
          targetPatterns?: StoreTypesOrgAccessControlTargetPattern[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addContractMembers: (
      state,
      action: {
        payload: {
          contractMembers?: StoreTypesOrgContractMembers[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addTenantOrganizations: (
      state,
      action: {
        payload: {
          tenantOrganizations?: ApiTypesOrganizationNameGetRes;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addThirdPlaceAreaSettings: (
      state,
      action: {
        payload: {
          thirdPlaceAreaSettings?: StoreTypesOrgThirdPlaceAreaSettings[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addDevices: (
      state,
      action: {
        payload: {
          devices?: V2StoreTypesOrgThing[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addFutureReservations: (
      state,
      action: {
        payload: {
          reservations?: StoreTypesOrgReservations[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addKeyItems: (
      state,
      action: {
        payload: {
          keyItems?: ContractKeyItem[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addNfcCards: (
      state,
      action: {
        payload: {
          nfcCards?: OrgNfcCard[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addChargeDetails: (
      state,
      action: {
        payload: {
          chargeDetails?: StoreTypesOrgChargeDetail[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addBillings: (
      state,
      action: {
        payload: {
          billings?: BillingResponse[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addBillingDetails: (
      state,
      action: {
        payload: {
          billingDetails?: ResGetBillingDetail[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addRefunds: (
      state,
      action: {
        payload: {
          refunds?: ResponseGetRefund;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addCreditCards: (
      state,
      action: {
        payload: {
          creditCards?: BceCreditCardType[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },

    addPayment: (
      state,
      action: {
        payload: {
          payment?: StoreTypesOrgPayment;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addCustomerPayments: (
      state,
      action: {
        payload: {
          payments?: StoreTypesOrgPayment[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addPeopleInCharge: (
      state,
      action: {
        payload: {
          peopleInCharge?: V2StoreTypesOrgPeople[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addControlFlag: (
      state,
      action: {
        payload: ContractDetailLoadedFlag;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          loaded: {
            ...state.control.loaded,
            inChargePeopleLoaded: action.payload.inChargePeopleLoad ?? state.control.loaded?.inChargePeopleLoad,
            customerLoad: action.payload.customerLoad ?? state.control.loaded?.customerLoad,
            spacesLoad: action.payload.spacesLoad ?? state.control.loaded?.spacesLoad,
            paymentLoad: action.payload.paymentLoad ?? state.control.loaded?.paymentLoad,
            creditCardsLoad: action.payload.creditCardsLoad ?? state.control.loaded?.creditCardsLoad,
            contractLoad: action.payload.contractLoad ?? state.control.loaded?.contractLoad,
            bankNameLoad: action.payload.bankNameLoad ?? state.control.loaded?.bankNameLoad,
            chargeDetailLoad: action.payload.chargeDetailLoad ?? state.control.loaded?.chargeDetailLoad,
            claimCyclesLoad: action.payload.claimCyclesLoad ?? state.control.loaded?.claimCyclesLoad,
            contractPlanLoad: action.payload.contractPlanLoad ?? state.control.loaded?.contractPlanLoad,
            billingsLoad: action.payload.billingsLoad ?? state.control.loaded?.billingsLoad,
            refundsLoad: action.payload.refundsLoad ?? state.control.loaded?.refundsLoad,
            nfcCardsLoad: action.payload.nfcCardsLoad ?? state.control.loaded?.nfcCardsLoad,
            customerMembersLoad: action.payload.nfcCardsLoad ?? state.control.loaded?.nfcCardsLoad,
            inChargePeopleLoad: action.payload.inChargePeopleLoad ?? state.control.loaded?.inChargePeopleLoad,
            thirdPlaceAreaSettingLoad:
              action.payload.thirdPlaceAreaSettingLoad ?? state.control.loaded?.thirdPlaceAreaSettingLoad,
          },
        },
      };
    },
    updateContractTabId: (
      state,
      action: {
        payload: ContractDetailHeaderTabId;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          tabId: action.payload,
        },
      };
    },
    updateSelectedContractVersion: (
      state,
      action: {
        payload: number;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          selectedContractVersion: action.payload,
        },
      };
    },
    addOneShotBillingId: (
      state,
      action: {
        payload: string;
      }
    ) => {
      return {
        ...state,
        added: {
          ...state.added,
          oneshotBillId: action.payload,
        },
      };
    },
    updateOpenDialogState: (
      state,
      action: {
        payload: ContractDetailDialogFlag;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          dialogOpen: action.payload,
        },
      };
    },
    updateControlVersion: (
      state,
      action: {
        payload: number;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          selectedContractVersion: action.payload,
        },
      };
    },
    addInCharge: (
      state,
      action: {
        payload: InChargeOfContract;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          inChargePeople: action.payload,
        },
      };
    },
    addShowBillingIds: (
      state,
      action: {
        payload: string[];
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          shownBillingIds: action.payload,
        },
      };
    },
    clear: () => initialState,
  },
});
export default contractDetailSlice;
